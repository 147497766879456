<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'CalculatorParent',
  data() {
    return {
      value: ''
    }
  }
}
</script>

<style></style>
